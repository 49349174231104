@import "../../../node_modules/normalize.css/normalize.css";

$darkGrey: hsla(0, 0%, 17.5%, 0.8);
$darkDarkGrey: hsl(0, 0%, 15%);
$lightGrey: hsl(0, 0%, 77%);
$nearBlack: hsl(0, 0%, 12%);
$nearWhite: hsl(0, 0%, 99%);
$darkerWhite: hsl(0, 0%, 96%);
$darkGreen: hsl(120, 55%, 15%);
$shadowy: #2223;
$smallerFont: 0.9rem;
$minMargin: 0.75rem;

html {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: $nearWhite;
    font-size: 1.25rem;
    height: 100%;
    line-height: 1.25;
}

body {
    background-color: $darkGreen;
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    transition: 2s ease-out opacity;
    flex-grow: 50;
    flex-basis: 30rem;
    padding: $minMargin ($minMargin / 2);
    display: flex;
    background: url("../img/img-noise-640x480.png");
}

footer {
    display: flex;
    transition-duration: 1.5s;
    flex-basis: 2.5rem;
    padding: 0.5rem 0.75rem;
    text-align: center;
    opacity: 0.7;
    font-size: $smallerFont;
    color: darken($nearWhite, 40);
    background-color: $darkGrey;

    a {
        display: inline-block;
    }

    .ipfs-logo {
        font-family: "Roboto Mono", monospace;
        font-size: $smallerFont * 0.85;
        background-image: url(../img/ipfs-logo-240x240.png);
        background-blend-mode: darken;
        padding-left: 2.75rem;
        min-height: 2.5rem;
        overflow: hidden;
        vertical-align: center;
        display: inline-block;
        background-size: 2.5rem;
        background-repeat: no-repeat;
    }
}

main.loading {
    opacity: 0.05;
    transition-duration: 3s;

    #full-screen {
        opacity: 1.0;
        background: red;
        margin: 10rem auto;
        height: 50rem;
    }
}

select option, label {
    font-size: 1rem;
}


header {
    padding: 0.5rem;
    flex-grow: 0;
}

main {
    li a {
        text-decoration: none;
    }

    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul.players li {
        display: flex;
        flex-direction: row;
    }

    fieldset {
        min-width: 14rem;
        background-color: transparentize($darkGrey, 0.50);
        padding: 0.25rem 0.5rem;
        margin: 0.25rem 0 0.5rem 0;
        border: 2px solid transparentize($darkGrey, 0.5);
        border-radius: 0.4rem;

        h3 {
            margin-top: 0;
        }
    }

    pre {
        opacity: 0.35;
    }

    small, .small {
        font-size: $smallerFont;
        opacity: 0.85;
        color: darken($nearWhite, 30);
    }

    #wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    button, input, select {
        margin: 0.25rem 0.5rem 0.75rem 0.5rem;
        background-color: $darkerWhite;
        border: 1px solid $darkGrey;
        color: $nearBlack;
    }

    button, input {
        min-width: 4rem;
        padding: 0.25rem;
        border-radius: 0.25rem;
    }

    a.button {
        display: inline-block;
        margin: 0.25rem 1.5rem 0.25rem 0.5rem;
        padding: 0.5rem 0.5rem;
        max-height: 1.25rem;
        width: content-box;
        background: $shadowy;
        color: $nearWhite;
        text-decoration: none;
        border-radius: 0.4rem;
        line-height: 1.0;
        opacity: 0.9;
        transition-duration: 0.1s;
    }

    a.button:hover {
        cursor: pointer;
        opacity: 1.0;
        filter: drop-shadow(0rem 0rem 0.2rem transparentize($darkGrey, 0.5)) brightness(150%);
        transition-duration: 0.25s;
    }

    select, button, option {
        opacity: 0.96;
        font-family: inherit;
    }

    select {
        padding: 0.1rem 0.25rem;
    }

    a.button.delete {
        font-size: 0.7rem;
        height: 0.75rem;
        width: 0.75rem;
        overflow: hidden;
        font-weight: 300;
        padding: 0.3rem;
        border-radius: 1rem;
        border: 2px solid transparent;
        color: hsla(15, 80, 50, 0.95);
        transition-duration: 0.1s;
    }

    .delete:hover {
        transition-duration: 0.75s;
        color: hsla(15, 80, 50, 1.0);
        background-color: hsla(15, 30, 12.5, 0.60);;
    }

    button:disabled {
        color: transparentize($nearBlack, 0.75);
    }

    .confirm-delete {
        transition-duration: 1500ms;
        animation-name: warb;
        animation-duration: 0.6s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    @keyframes warb {
        from {
            border-color: hsla(60, 35%, 30%, 90%);
            opacity: 0.40;
        }
        to {
            border-color: hsla(60, 85%, 30%, 90%);
            background-color: hsla(60, 30%, 20%, 45%);
            opacity: 1.0;
        }
    }

    .fixed {
        flex-grow: 1 !important;
    }

    .panel {
        // Make sure it's larger than the margins allow
        max-width: 95vw;
        min-width: 16rem;
        overflow: hidden;
        flex-grow: 20;
        flex-shrink: 20;
        background-color: $shadowy;
        border-radius: 0.5rem;
        padding: 0.5rem 0.75rem;

        h2 {
            margin-top: -0.25rem;
        }

        margin: 0 ($minMargin / 2) $minMargin ($minMargin / 2);
    }

    .hint {
        padding: 1.5rem 0.25rem;
        color: darken($nearWhite, 15%);
        font-size: 1.35rem;
        background: transparentize($darkGrey, 0.75);
        border-radius: 0.5rem;
        min-width: 25vw;
    }

    #game-setup {
        flex-grow: 1;
        flex-shrink: 1;
    }

    #players-setup {
        flex-shrink: 1;
        flex-grow: 50;
    }

    #games-list {
        overflow: hidden;

        li {
            display: flex;
            align-items: center;
            padding: 0.25rem 0;

            .button {
                margin-right: 0.25rem;
            }
        }

        .game {
            max-width: 22rem;
            min-width: 14rem;
            margin: 0;
            cursor: pointer;

            a.details {
                overflow-x: hidden;
                margin-right: 0.5rem;
                flex-grow: 2;
            }
        }
    }

    #nav-bar {
        min-width: auto;
        flex-grow: 0;
        a {
            font-size: 1.5rem;
            opacity: 0.8;
            transition-duration: 0.25s;
            filter: grayscale(80%);
        }
        padding: 0.5rem;
        a:hover { opacity: 0.925; filter: grayscale(35%);}
    }

    #score-table {
        flex-grow: 20;
        font-size: 1.5rem;
        display: flex;
        overflow-x: scroll;

        .names {
            .name {
                border: none;

                h3 {
                    margin-top: 0;
                }
            }
        }

        #totals {
            text-align: right;
            font-weight: 400;
        }

        .round {
            text-align: center;
            display: flex;
            flex-direction: column;
        }

        .name {
            word-wrap: normal;
            min-width: 8rem;
            border: none;
        }

        .name, .player-round, .total {
            padding: 0 0.25rem;
            height: 3.5rem;
            overflow: hidden;
        }

        .player-round {
            color: #AAA;
            font-weight: 300;
            font-size: 0.75rem;
            width: 3rem;
            flex-wrap: nowrap;
            display: flex;
            flex-direction: column;

            .won, .bid {
                width: 2.5rem;
                overflow: hidden;

                div {
                    overflow: hidden;
                    position: relative;
                    display: inline-block;
                    z-index: -1;
                }
            }

            .emoji {
                opacity: 0.8;
                font-size: 1.1rem;
            }

            .won div {
                margin-left: -0.75rem;
                margin-top: -0.3rem;
                font-size: 2.0rem;
                opacity: 0.2;
            }

            .bid div {
                margin-top: -0.6rem;
                margin-left: -0.75rem;
                font-size: 2.5rem;
                opacity: 0.15;
            }

            .sub-total {
                margin: 0.25rem 0;
                text-align: center;
                width: 2.8rem;
                display: inline-block;
            }
        }
    }


    table {
        font-size: 0.9rem;
        border: 2px solid #444;
        background: linear-gradient(-55deg, #111, #333);
        padding: 0.5rem;
    }

    #controls {
        flex-grow: 0;
        font-weight: 300;

        label {
            margin-left: 1.25rem;
            font-family: inherit;
        }

        select {
            background: inherit;
            border: 0.15rem solid transparentize($lightGrey, 0.9);
            border-radius: 0.25rem;
            margin: 0 0.5rem 0 0.5rem;
            text-align: right;
            color: inherit;
            font-weight: 600;

            option {
                width: 1.5rem;
                background: $darkDarkGrey;
                text-align: right;
            }
        }

        h2 {
            margin-top: 0;
        }
    }
}

nav {
    font-size: 1.25rem;
}

a {
    color: inherit;
}

a:visited {
    color: inherit;
}


.emoji {
    font-family: serif;
    font-size: 1.25rem;
    display: block;
}

h1, h2, h3, h4 {
    font-weight: 200;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    clear: both;
}

h1, h2 {
    margin-bottom: 0.1rem;
    margin-left: -0.25rem;
    font-size: 3rem;
}

h2 {
    font-size: 2.1rem;
}

h3, h4 {
    font-size: 1.25rem;
    margin-bottom: 0.1rem;
    margin-top: 0.75rem;
}

h4 {
    font-size: 1rem;
    margin-top: 0.25rem;
}

a:hover {
    opacity: 1.0;
}



